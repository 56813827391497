import React from 'react'
import { RichText } from 'prismic-reactjs'
import PropTypes from "prop-types"

import ResponsiveImage from '../responsiveImage/responsiveImage'
 
const TextImageColumns = ( props ) => {
  if (props.section.primary.section_title === null && props.section.primary.section_text === null) {
    return null
  }

  return (
    <React.Fragment>
      {props.section.primary.image !== null && 
        <section className="hide-on-md">
          <ResponsiveImage
            src={props.section.primary.image.url}
            width={props.section.primary.image.dimensions.width}
            height={props.section.primary.image.dimensions.height}
            alt={props.section.primary.image.alt}
            widths={['2200', '1500', '750']}
            classes="full-width-image"
          />
        </section>
      }
      <div className={`section-slim`}>
        {props.section.primary.text_image_title &&
          <div className="limit-width">
            <h2 className={`t-h2 section-title section-title-fw`}>{RichText.asText(props.section.primary.text_image_title)}</h2>
          </div>
        }
        <div className="cols-wrapper">
          <div className="cols">
            <div className={`col-5-md col-5-lg`}>
              <div className={`t-body text-body`}>
                {RichText.render(props.section.primary.text)}
              </div>
            </div>
            {props.section.primary.image !== null && 
              <div className={`col-5-md col-5-lg push-1-md push-1-lg show-on-md`}>
                <ResponsiveImage
                  src={props.section.primary.image.url}
                  width={props.section.primary.image.dimensions.width}
                  height={props.section.primary.image.dimensions.height}
                  alt={props.section.primary.image.alt}
                  widths={['2200', '1500', '750']}
                  classes="image-block"
                  cropRatio="0.833333:1"
                />
              </div>
            }
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}

TextImageColumns.propTypes = {
  section: PropTypes.object
}

TextImageColumns.defaultProps = {
  section: {}
}
 
export default TextImageColumns