import React from "react"
import { graphql } from "gatsby"

import SEO from "../components/seo"

import PageHeader from '../components/pageHeader/pageHeader'
import StandardTextSection from '../components/standardTextSection/standardTextSection'
import TextImageColumns from '../components/textImageColumns/textImageColumns'
import FullWidthImage from '../components/fullWidthImage/fullWidthImage'
import AboutGrid from '../components/aboutGrid/aboutGrid'
   
export const query = graphql`
  query AboutQuery {
  prismic {
    allAbouts {
      edges {
        node {
          title
          introduction_text
          background_video {
            ... on PRISMIC__ImageLink {
              _linkType
              url
              width
              height
            }
            ... on PRISMIC__FileLink {
              _linkType
              url
            }
          }
          _meta {
            uid
          }
          body {
            ... on PRISMIC_AboutBodyText_section {
              type
              label
              primary {
                section_text
                section_title
              }
            }
            ... on PRISMIC_AboutBodyText___image_columns {
              type
              label
              primary {
                image
                text
                text_image_title
              }
            }
            ... on PRISMIC_AboutBodyFull_width_image {
              type
              label
              primary {
                image
              }
            }
            ... on PRISMIC_AboutBodyGrid {
              type
              label
              primary {
                grid_title
                introduction_text
              }
              fields {
                block_image
                block_text
                block_title
              }
            }
          }
        }
      }
    }
  }
}
`

const About = ({ data }) => {
  const doc = data.prismic.allAbouts.edges.slice(0,1).pop();
  if (!doc) return null;

  return (
    <React.Fragment>
      <SEO title="About" />
      <PageHeader
        title={doc.node.title}
        text={doc.node.introduction_text}
        id="overview"
        background={doc.node.background_video}
      />
      <div style={{
        overflow: "auto"
      }}>
        {doc.node.body !== null ? doc.node.body.map((section, index) => {
          switch (section.type) {
            case 'text_section':
              return (
                <StandardTextSection section={section} seamless={true} key={index} align="center" />
              )
            case 'text___image_columns':
              return (
                <TextImageColumns section={section} key={index} />
              )
            case 'full_width_image':
              return (
                <FullWidthImage section={section} key={index} />
              )
            case 'grid':
              return (
                <AboutGrid section={section} key={index} />
              )
            default:
              return null
          }
        }) : null}
      </div>
    </React.Fragment>
  )
}

export default About
