import React from 'react'
import { RichText } from 'prismic-reactjs'
import PropTypes from "prop-types"
 
const StandardTextSection = ( props ) => {
  if (props.section.primary.section_title === null && props.section.primary.section_text === null) {
    return null
  }

  return (
    <div className={`${props.seamless === true ? `section-slim-seamless` : `section-slim`}`} id={props.id}>
      <div className="cols-wrapper">
        <div className="cols">
          <div className={props.align === 'center' ? `col-12-md col-10-lg push-1-lg` :  `col-12-md col-10-lg`}>
            {!!props.section.primary.section_title &&
              <h2 className={`t-h2 section-title section-title-fw`}>{RichText.asText(props.section.primary.section_title)}</h2>
            }
            {!!props.section.primary.section_text &&
              <div className={`text-body`}>
                {RichText.render(props.section.primary.section_text)}
              </div>
            }
          </div>
        </div>
      </div>
    </div>
  )
}

StandardTextSection.propTypes = {
  section: PropTypes.object,
  seamless: PropTypes.bool,
  align: PropTypes.string
}

StandardTextSection.defaultProps = {
  section: {},
  seamless: false,
  align: 'center'
}
 
export default StandardTextSection