import React from 'react'
import { RichText } from 'prismic-reactjs'
import PropTypes from "prop-types"

import styles from './aboutGrid.module.scss'

import ResponsiveImage from '../responsiveImage/responsiveImage'
 
const AboutGrid = ( props ) => {
  if (props.section.primary.section_title === null && props.section.primary.section_text === null) {
    return null
  }

  return (
    <section>
      <div className="section-slim-seamless">
        <div className="cols-wrapper">
          <div className="cols">
            <div className={`col-10-md push-1-md col-10-lg push-1-lg`}>
              {RichText.asText(props.section.primary.grid_title) &&
                <h2 className={`t-h2 section-title section-title-fw`}>{RichText.asText(props.section.primary.grid_title)}</h2>
              }
              {!!props.section.primary.introduction_text &&
                <div className={`t-h3 text-body`}>
                  {RichText.render(props.section.primary.introduction_text)}
                </div>
              }
            </div>
          </div>
        </div>
      </div>
      <div className={styles.section}>
        <div className="limit-width">
          {props.section.fields.map((item, index) => {
            if (item.block_image === null && item.block_text === null) {
              return null
            }

            return (
              <div
                className={styles.row}
                key={index}
              > 
                <div className={styles.image}>
                  {item.block_image !== null && 
                    <ResponsiveImage
                      src={item.block_image.url}
                      width={item.block_image.dimensions.width}
                      height={item.block_image.dimensions.height}
                      alt={item.block_image.alt}
                      widths={['2200', '1500', '750']}
                    />
                  }
                </div>
                <div className={styles.text}>
                  <div className={styles.textWrap}>
                    <div className={`${styles.textInner}`}>
                      <div className="t-body text-body">
                        {RichText.asText(item.block_title) &&
                          <h3>{RichText.asText(item.block_title)}</h3>
                        }
                        {RichText.render(item.block_text)}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )
          })}
        </div>
      </div>
    </section>
  )
}

AboutGrid.propTypes = {
  section: PropTypes.object
}

AboutGrid.defaultProps = {
  section: {}
}
 
export default AboutGrid