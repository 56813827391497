import React from 'react'
import PropTypes from "prop-types"

import ResponsiveImage from '../responsiveImage/responsiveImage'
 
const FullWidthImage = ( props ) => {
  if (props.section.primary.image === null) {
    return null
  }

  return (
    <section>
      {props.section.primary.image !== null && 
        <ResponsiveImage
          src={props.section.primary.image.url}
          width={props.section.primary.image.dimensions.width}
          height={props.section.primary.image.dimensions.height}
          alt={props.section.primary.image.alt}
          widths={['2200', '1500', '750']}
          classes="full-width-image"
        />
      }
    </section>
  )
}

FullWidthImage.propTypes = {
  section: PropTypes.object
}

FullWidthImage.defaultProps = {
  section: {}
}
 
export default FullWidthImage